import styles from "./Footer.module.scss"

function Footer(props: any) {
    return <footer className={styles.component}>
        <div className="wrapper">
            <div className="note">
                <a href="https://www.fcheimberg.ch/" target="_blank">FC Heimberg</a>
            </div>
            <div className="main-sponsors">
                <div><img src={process.env.PUBLIC_URL + "/logo-aek.png"} alt="AEK Bank 1826" /></div>
                <div><img src={process.env.PUBLIC_URL + "/logo-belcom.png"} alt="BelCom Elekto GmbH" /></div>
                <div><img src={process.env.PUBLIC_URL + "/logo-esfera.png"} alt="esfera GmbH" /></div>
                <div><img src={process.env.PUBLIC_URL + "/logo-hth.png"} alt="HTH Hoch+Tiefbau AG" /></div>
                <div><img src={process.env.PUBLIC_URL + "/logo-migros.png"} alt="Migros Genossenschaft Aare" /></div>
                <div><img src={process.env.PUBLIC_URL + "/logo-ochsner-sport.png"} alt="Ochsner Sport" /></div>
                <div><img src={process.env.PUBLIC_URL + "/logo-wb.png"} alt="WB AG Heizung Sanitär" /></div>
            </div>
        </div>
    </footer>
}

export default Footer;