import { useEffect, useRef } from "react";
import styles from "./NotificationFlyout.module.scss";

function NotificationFlyout(props: any) {
    let { notification, setNotification }: { notification: { title: string, body: JSX.Element, type: string, autoHide: boolean }, setNotification: Function } = props;

    let rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!notification.autoHide) {
            return;
        }

        let currentRoot = rootRef.current;

        if (currentRoot) {
            currentRoot.addEventListener('animationend', closeNotification);
        }

        return () => {
            if (currentRoot) {
                currentRoot.removeEventListener('animationend', closeNotification);
            }
        }
    });

    const closeNotification = (e: any) => {
        if (e) {
            setNotification(undefined);
        } else {
            let currentRoot = rootRef.current;

            if (currentRoot) {
                currentRoot.style.animation = "hideNotification .5s linear forwards";
                currentRoot.addEventListener('animationend', closeNotification);
            }
        }
    };

    return <div className={styles.component} ref={rootRef} style={{animation: (notification.autoHide ? "flashNotification" : "stickyNotification") + " 7s linear forwards"}}>
        <div className={"notification__type notification__type--" + notification.type}></div>
        <h3>{notification.title}</h3>
        {notification.body}
        <button onClick={() => closeNotification(null)}>Schliessen</button>
    </div>
}

export default NotificationFlyout;