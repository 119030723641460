import React, { useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./Header.module.scss";
import { Fireworks } from 'fireworks-js';

function Header(props: any) {
  const headerRef = useRef<HTMLElement>(null);
  const navigationRef = useRef<HTMLDivElement>(null);

  const toggleNavigation = () => {
    let currentRef = navigationRef.current;

    if (currentRef) {
      if (!currentRef.classList.contains("open")) {
        currentRef.classList.add("open");
      } else {
        currentRef.classList.remove("open");
      }
    }
  }

  useEffect(() => {
    let currentRef = headerRef.current;

    if (currentRef && currentRef.querySelectorAll("canvas").length === 0) {
      const fw = new Fireworks(currentRef, {
        hue: {
          min: 200,
          max: 200
        },
        particles: 50,
        brightness: {
          min: 90,
          max: 100
        },
        lineWidth: {
          explosion: {
            min: 1,
            max: 1
          },
          trace: {
            min: 1,
            max: 2
          },
        },
        traceSpeed: 1,
        flickering: 3,
        delay: {
          min: 100,
          max: 2000
        }
      });

      fw.start();

      //fw.launch(50);
    }
  }, []);

  return <header className={styles.component} ref={headerRef}>
    <div className="wrapper">
      <div className="logo">
        <Link to={"/"} className="logo-pwe">
          <img src={process.env.PUBLIC_URL + "/logo-pwe-white.svg"} alt="Powerweekend 2024" />
        </Link>
        <img src={process.env.PUBLIC_URL + "/90jahre.svg"} alt="90 Jahre FC Heimberg" className="logo-anniversary" />
      </div>

      <div className="navigation" ref={navigationRef}>
        <div className="open-navigation" onClick={() => toggleNavigation()}><span></span></div>
        <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/">Home</NavLink>
        <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/programm">Programm</NavLink>
        {/*<NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/plauschturnier">Mixed Plauschturnier</NavLink>
        <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/waldgartencup">Waldgarten-Cup</NavLink>*/}
        <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/einsatzplan">Einsatzplan</NavLink>
        <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/spielplan">Spielplan Turniere</NavLink>
        {/*<NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/archiv">Archiv</NavLink>*/}
      </div>
    </div>
  </header>
}

export default Header;